.client-details-form {
    label {
        font-size: 14px;
        font-weight: 400;
        color: #525355;
    }

    input {
        border-radius: 2px;
    }

    .input-number {
        width: 100%;
    }
}