@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?5b1ahy');
  src:  url('fonts/icomoon.eot?5b1ahy#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?5b1ahy') format('truetype'),
    url('fonts/icomoon.woff?5b1ahy') format('woff'),
    url('fonts/icomoon.svg?5b1ahy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icomoon, sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-leftarrow:before {
  content: "\e903";
}
.icon-delete:before {
  content: "\e902";
}
.icon-closeable:before {
  content: "\e901";
}
.icon-downarrow:before {
  content: "\e900";
}
