.create-client-btn {

    @media screen and (max-width:575px) {
        width: 100%;
    }
}

.create-client-wrap {
    .ant-card-head-wrapper {
        @media screen and (max-width:575px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .ant-card-extra {
        margin-left: 0;
        padding: 10px 0;

        @media screen and (max-width:575px) {
            width: 100%;
        }
    }

    .ant-empty-image {
        height: 60px;
    }

    .ant-card-head-title {
        font-size: 20px;
        font-weight: 700;
        color: #525355;
        padding: 0;


        @media screen and (max-width:575px) {
            padding-top: 10px;
        }
    }

    
}

.create-client-modal {
    border-radius: 10px;

    .ant-modal-content {
        border-radius: 10px;
    }

    .ant-modal-title {
        font-size: 20px;
        font-weight: 700;
        color:
            #525355;
    }

    .ant-modal-header {
        border-bottom: none;
        border-radius: 10px;
        @media screen and (min-width:400px) {
            padding: 30px 24px 16px 40px;
        }
        @media screen and (max-width:399px) {
            padding: 16px 20px;
        }
    }

    .ant-modal-body {
        @media screen and (min-width:400px) {
            padding: 24px 40px 24px 40px;
        }
        @media screen and (max-width:399px) {
            padding: 24px;
        }
    }

    .ant-input {
        border-radius: 2px;
    }

    .closeBtn {
        position: relative;

        &>i {
            font-size: 10px;
            color: #0078A1;
            position: absolute;
            top: 3px;
            right: 15px;
        }
    }

    .ant-form-item {
        margin-bottom: 20px;
    }

    .ant-form-item-label {
        &>label {
            color: #525355;

        }
    }

    .ant-modal-footer {
        border-top: none;
        background: #F7F7F7;
        border-radius: 0px 0px 10px 10px;
        padding: 20px;

        @media screen and (min-width:400px) {
            padding: 20px 40px
        }
    }

    .input-number {
        width: 100%;
    }
}

.modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .delete-btn {
        padding: 0;
        font-weight: 700;
        color: #525355;
        display: flex;
        align-items: center;

        &>i {
            font-size: 16px;
            margin-right: 10px;
            color: #0B7FAE;
        }
    }

    .add-btn {
        background: #EB712F;
        border-radius: 5px;
        color: white;
        border: none;
        margin-left: 0 !important;

    }
}