.mapping-form{
    &>:not(:first-child){
        margin-top: 20px;
    }
    label{
        font-weight: 700;
        font-size: 14px;
        margin-right: 25px;
        color: #525355;

    }
    input{
        border-radius: 2px;
    } 
    .mapping-form-border{
        border: 1px dashed #D9D9D9;
        padding: 20px;
        .mapping-input-feild{
            display: flex;
            // justify-content: space-between;
            gap: 20px;
        }
        .mapping-replace-row {
            &>:not(:first-child){
                margin-top: 20px;
            }
            &>div{
                @media screen and (max-width:575px) {
                    row-gap: 10px !important;
                }
            }
            
        }
    }

    .upload-btn {
        border-radius: 5px;
    }

    .upload-btn-with-label {
        display: flex;
    }

    .upload-btn-label {
        margin: 5px 0 0 15px;
    }

    .upload-btn-span {
        color: green;
        font-weight: normal;
    }
}

.mapping-input{  
    display: flex;
    gap: 16px;

    &>:last-child{
        margin-top: auto;
    }
    .mapping-form-item{
        width: calc(100% - 50px);
        
    }
    .mapping-plus-btn{
        span{
            font-size: 11px;
        }
    }
}
.mapping-result{
 .ant-result-icon{
  .anticon {
    color: #FF4D4F;
    font-size: 60px;
 }
}
.ant-result-title{
    font-weight: 400;
    font-size: 14px;
}
}