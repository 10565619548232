.client-details_info-card{
    border-radius: 5px;
    .ant-card-head{
        padding: 0 24px;
    }
 
    .ant-tabs-ink-bar-animated{
        display: none;
    }
    .ant-tabs-nav{
        &::before{
            display: none !important;
        }
        @media screen and (min-width:768px) {
            flex-direction: row-reverse;
            }
        
        @media screen and (max-width:767px) {
            flex-direction: column-reverse;
            align-items: flex-start;
            }
    }
    .ant-tabs-nav-wrap{
        @media screen and (min-width:768px) {
        justify-content: flex-end;
        }
        &::before, &::after{
            display: none;
          }
          .ant-tabs-nav-list {
            transform:none !important;
          }
    }
    .ant-tabs-extra-content{
        a{
            display: flex;
            align-items: center;
            @media screen and (max-width:767px) {
                margin-top: 18px;
            }
            @media screen and (max-width:440px) {
                margin-top: 15px;

                }
                .icon-leftarrow{
                margin-right: 16px;
                font-size: 16px;
                color: #0078A1;
            }
            h4{
                font-weight: 700;
                font-size: 20px;
                color: #525355;
                margin-bottom: 0;
               
            }
        }
    }
    .ant-tabs-nav-operations{
        display: none !important;
    }
    .ant-tabs-nav-list{
        @media screen and (max-width:480px) {
           flex-wrap: wrap;
            }
            .ant-tabs-tab{
                font-weight: 400;
                font-size: 14px;
                padding: 18px 0;
                color: #525355;
 
                @media screen and (max-width:480px) {
                margin: 0;
                width: 50%;
                padding: 10px 0;
                }
            }
  
    }
    
}


