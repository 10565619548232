@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap");
$primary: #1e2144;
$text-primary: $primary;
@import "assets/fonts/stylesheet.css";
//@import "assets/scss/theme.less";
@import "assets/scss/utilities";
@import "assets/scss/global";
@import "assets/scss/login";
@import url("assets/fonticons/style.css");
@import "./assets/scss/header";
@import "assets/scss/clientDetailsInfo";
@import "assets/scss/createClient";
@import "./assets/scss/clientDetails";
@import "./assets/scss/fileConfiguration";
@import "./assets/scss/mapping";
@import "./assets/scss/eeidMappings";
@import "assets/scss/profileManagement";