.file-configuration-form {
  label {
    font-size: 14px;
    font-weight: 400;
  }
  input {
    border-radius: 2px;
  }

  .input-number {
    width: 100%;
  }

  .ant-picker {
    width: 100%;
  }

  .configuration-plus-btn {
    margin-top: auto;
  }
  .configuration-file-count {
    margin-top: 40px;
    margin-bottom: 20px;
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .header-row {
    display: flex;
    gap: 20px;
    .header-row-input {
      width: calc(100% - 50px);
    }
    .header-row-plus {
      margin-top: auto;
      span {
        font-size: 11px;
      }
    }
  }
  .add-more-btn {
    width: 150px;
    float: right;
  }
}
