.eeid-mappings-form {
    .upload-btn {
        border-radius: 5px;
    }
    
    .upload-btn-with-label {
        display: flex;
    }
    
    .upload-btn-label {
        margin: 5px 0 0 15px;
    }
    
    .upload-btn-span {
        color: green;
        font-weight: normal;
    }
}