.login-container {
  @extend .w-100;
  max-width: 330px;
  margin: auto;
  padding: 3rem 0;
}

.lc-logo {
  @extend .text-center;
  margin-bottom: 3rem;

  img {
    max-width: 200px;
  }
}

.login-card {
      border: none !important;

  h4 {
    @extend .mb-4, .text-center;
    font-size: 20px;
    font-weight: 700;
  }
  .ant-checkbox-wrapper{
    color:#047A9E;

  }
}
