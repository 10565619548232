body {
  font-family: AvenirLTProRoman, sans-serif;
}

img {
  max-width: 100%;
}

.text-primary {
  color: $text-primary;
}

.font-weight {
  &-normal {
    font-family: Avenir LT Std, sans-serif;
  }

  &-medium {
    font-family: sf_pro_displaymedium, sans-serif;
  }

  &-semibold {
    font-family: sf_pro_displaysemibold, sans-serif;
  }

  &-black {
    font-weight: 900;
    font-family: sf_pro_displaybold, sans-serif;
  }
}



.ant-layout {
  min-height: 100vh;

  &-sider {
    @extend .bg-white;
    box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.15);
    margin-right: 1px;

    &-logo {
      max-width: 135px;
      margin: 30px auto;
    }
  }

  &-header {
    @extend .bg-white;
  }

  &.guest-layout {
    .ant-layout-content {
      @extend .d-flex,
      .align-items-center,
      .position-relative;
    }
  }
}

.ant-spin {
  @extend .position-absolute,
  .w-100,
  .h-100;
  left: 0%;
  top: 0%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 999;

  .ant-spin-dot-spin {
    @extend .position-absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
  }
}

.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 11px;
}

.app-header-right {
  float: right;

  .loggedin-user-dd {
    &>a {
      @extend .font-weight-medium;
      color: #262626;

      .ant-avatar,
      .anticon {
        margin: 0 5px;
      }

      .anticon {
        &-down {
          color: #8c8c8c;
        }
      }
    }
  }
}

.layout-content {

  @media screen and (min-width:576px) {
    padding: 30px 40px 30px 40px;
  }

  @media screen and (max-width:575px) {
    padding: 20px;
  }

}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #8DB936;
  text-shadow: 0 0 0.25px currentcolor;
}

.client-details-btn {
  padding-top: 40px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.gradient-btn {
  background: linear-gradient(90deg, #0078A1 0%, #8DB936 100%);
  border-radius: 5px;
  color: white;
  border: transparent;

  &:hover,
  &:focus {
    background: linear-gradient(90deg, #0078A1 0%, #8DB936 100%);
    color: white;
  }
}

.guest-layout {
  background-color: #F0F2F5;
}

.site-form-item-icon {
  color: #BFBFBF;
}

.ant-input-password-icon.anticon {
  color: #047A9E;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    color: #047A9E;
  }
}

.cancel-btn {
  background: #E1E1E1;
  border-radius: 5px;
  margin-right: 15px;

  @media screen and (min-width:350px) {
    margin-right: 20px;
  }
  
  &:hover,
  &:focus {
    background: #E1E1E1;
    color: black;
    border-color: transparent;
  }
}

.ant-card {
  border: 1px solid #D9D9D9;
  border-radius: 5px;

  .ant-card-head {
    border-bottom-color: #D9D9D9;
  }
}
.common-table {
  .ant-table-filter-trigger {
      color: #0078A1;
  }
  .ant-empty-img-simple {
      width: 90px;
  }

  .ant-empty-img-simple-g {
      stroke: #0B7D99;
  }

  .ant-empty-description {
      color: #525355;
      font-size: 16px;
  }

  .ant-empty-img-simple-ellipse {
      fill: transparent;
  }

  .ant-empty-img-simple-path {
      fill: rgba(134, 182, 59, 0.15);

  }

  .ant-table-tbody {
      &>:last-child {
          .ant-table-cell {
              border-bottom: none;
          }
      }
      .ant-table-placeholder{
          .ant-table-cell{
          padding: 0 !important;
          }
      }
  }

  table {
      @media screen and (max-width:992px) {
          min-width: 840px !important;
      }

  }

  .ant-table.ant-table-small .ant-table-tbody>tr>td {
      padding-left:16px;
      padding-right:16px;
  }

}

.common-table-row:hover {
  cursor: pointer;
}

.job-tags .ant-tag {
  font-size: 13px;
  padding: 3px 8px;
  border-radius: 20px;
}

.table-switch {
  &.ant-switch-checked{
    background: linear-gradient(90deg, #0078A1 0%, #8DB936 100%);
  }
}