.app-header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F0F0F0;
    padding: 0 40px;

    @media screen and (max-width:430px) {
        padding: 0 20px 0 0;
    }

    @media screen and (min-width:431px) and (max-width:575px) {
        padding: 0 20px;
    }

    .app-logo-wrap {
        @media screen and (max-width:767px) {
            overflow: hidden;
            width: 39px;
        }

        .app-logo {
            @media screen and (max-width:767px) {
                max-width: unset;
            }
        }
    }



    .app-header-right {
        display: flex;
        align-items: center;
        gap: 48px;

        @media screen and (min-width:576px) and (max-width:767px) {
            gap: 30px;
        }

        @media screen and (max-width:575px) {
            gap: 20px;
        }

        .app-navbar {


            &>ul {
                margin-bottom: 0;
                padding-left: 0;
                display: flex;
                justify-content: space-between;
                gap: 40px;
                list-style-type: none;

                @media screen and (min-width:576px) and (max-width:767px) {
                    gap: 30px;
                }

                @media screen and (max-width:575px) {
                    gap: 20px;

                }

                &>li {
                    .ant-typography {
                        font-size: 14px;
                        color: #525355;
                    }

                    .active {
                        color: #0078A1;
                        border-bottom: 2px solid #0078A1;
                        padding-bottom: 21px;
                    }

                    .not-active {
                        color: #525355;
                    }
                }
            }
        }

        .user-profile-wrap {
            display: flex;
            align-items: center;
            gap: 8px;

            .user-name {
                color: #525355;

                @media screen and (max-width:575px) {
                    display: none;
                }
            }

            &>i {
                color: #0078A1;
                font-size: 8px !important;
                margin-top: 2px;

                @media screen and (max-width:575px) {
                    display: none;
                }
            }

        }


    }

}

.ant-dropdown {
    min-width: 188px !important;
}

.dropdown-menu {
    padding: 1px;
    border-radius: 4px;

    .menu-item {
        padding: 10px 16px;
        font-size: 16px;
        color: #525355;

    }

    .ant-dropdown-menu-item {
        overflow: hidden;
    }

    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-item-active {
        background: #03799E !important;
        color: white;
        transition: none;

    }

    &>:first-child {
        border-radius: 4px 4px 0 0;
    }

    &>:last-child {
        border-radius: 0 0 4px 4px !important;
    }
}