@font-face {
    font-family: 'AvenirLTProRoman';
    src: url('AvenirLTProRoman.eot');
    src: url('AvenirLTProRoman.eot') format('embedded-opentype'),
         url('AvenirLTProRoman.woff2') format('woff2'),
         url('AvenirLTProRoman.woff') format('woff'),
         url('AvenirLTProRoman.ttf') format('truetype'),
         url('AvenirLTProRoman.svg#AvenirLTProRoman') format('svg');
}
@font-face {
    font-family: 'AvenirLTProLight';
    src: url('AvenirLTProLight.eot');
    src: url('AvenirLTProLight.eot') format('embedded-opentype'),
         url('AvenirLTProLight.woff2') format('woff2'),
         url('AvenirLTProLight.woff') format('woff'),
         url('AvenirLTProLight.ttf') format('truetype'),
         url('AvenirLTProLight.svg#AvenirLTProLight') format('svg');
}
@font-face {
    font-family: 'AvenirLTProBook';
    src: url('AvenirLTProBook.eot');
    src: url('AvenirLTProBook.eot') format('embedded-opentype'),
         url('AvenirLTProBook.woff2') format('woff2'),
         url('AvenirLTProBook.woff') format('woff'),
         url('AvenirLTProBook.ttf') format('truetype'),
         url('AvenirLTProBook.svg#AvenirLTProBook') format('svg');
}
@font-face {
    font-family: 'AvenirLTProMedium';
    src: url('AvenirLTProMedium.eot');
    src: url('AvenirLTProMedium.eot') format('embedded-opentype'),
         url('AvenirLTProMedium.woff2') format('woff2'),
         url('AvenirLTProMedium.woff') format('woff'),
         url('AvenirLTProMedium.ttf') format('truetype'),
         url('AvenirLTProMedium.svg#AvenirLTProMedium') format('svg');
}
@font-face {
    font-family: 'AvenirLTProHeavy';
    src: url('AvenirLTProHeavy.eot');
    src: url('AvenirLTProHeavy.eot') format('embedded-opentype'),
         url('AvenirLTProHeavy.woff2') format('woff2'),
         url('AvenirLTProHeavy.woff') format('woff'),
         url('AvenirLTProHeavy.ttf') format('truetype'),
         url('AvenirLTProHeavy.svg#AvenirLTProHeavy') format('svg');
}

@font-face {
    font-family: 'AvenirLTProBlack';
    src: url('AvenirLTProBlack.eot');
    src: url('AvenirLTProBlack.eot') format('embedded-opentype'),
         url('AvenirLTProBlack.woff2') format('woff2'),
         url('AvenirLTProBlack.woff') format('woff'),
         url('AvenirLTProBlack.ttf') format('truetype'),
         url('AvenirLTProBlack.svg#AvenirLTProBlack') format('svg');
}