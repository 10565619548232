.profile-management-form{
    label{
        font-weight: 400;
        font-size: 14px;
        color: #525355;

    }
    input{
        border-radius: 2px;
    }
    .ant-card-head-title{
        font-weight: 700;
        font-size: 20px;
        color: #525355;

    }
}